import React, { useState } from 'react';
import '../App.css';
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import SummaryApi from '../common';

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
    confirmPassword: "",
   
  });

  const navigate = useNavigate()

  const handleOnChange = async (e) => {
    const { name, value } = e.target;
    setData((preve) => {
      return {
        ...preve,
        [name]: value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.password === data.confirmPassword) {
      try {
        const dataResponse = await fetch(SummaryApi.updateuserpasswordReset.url, {
          method: SummaryApi.updateuserpasswordReset.method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const dataApi = await dataResponse.json();
        if (dataApi.success) {
          toast.success(dataApi.message)
          navigate("/login")
        }
        if (dataApi.error) {
          toast.error(dataApi.message)
          // navigate("/login")
        }

      } catch (error) {
        toast("An error occurred. Please try again.");
        console.error("Error:", error);
      }
    } else {
      toast.error("The passwords do not match");
      console.log("The passwords do not match");
    }
  };

  return (
    <div>
      {/* <Routes>
        <Route path="/" element={<Header />} />
      </Routes> */}
      <section id='login'>
        <div className='mx-auto container p-4'>
          <div className='bg-white p-5 w-full max-w-sm mx-auto'>
            <h1 className='items-center text-center font-medium'> Reset Your Password Here</h1>
            <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
              <div className='grid'>
                <label>Full Name / Business Name</label>
                <div className='bg-slate-100 p-2'>
                  <input
                    type='text'
                    required
                    value={data.name}
                    onChange={handleOnChange}
                    name='name'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Your username'
                  />
                </div>
              </div>
              <div className='grid'>
                <label>Email</label>
                <div className='bg-slate-100 p-2'>
                  <input
                    type='email'
                    required
                    value={data.email}
                    onChange={handleOnChange}
                    name='email'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Account email address'
                  />
                </div>
              </div>
              <div className='grid'>
                <label>New Password</label>
                <div className='bg-slate-100 p-2 flex'>
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    name='password'
                    onChange={handleOnChange}
                    value={data.password}
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Password'
                  />
                  <div className='cursor-pointer text-xl' onClick={() => setShowPassword((prev) => !prev)}>
                    <span>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <div className='grid'>
                <label>Confirm Password</label>
                <div className='bg-slate-100 p-2 flex'>
                  <input
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name='confirmPassword'
                    onChange={handleOnChange}
                    value={data.confirmPassword}
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Confirm Password'
                  />
                  <div className='cursor-pointer text-xl' onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    <span>
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <button className='hover:bg-blue-600 bg-orange-600 text-white px-5 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                Reset Password
              </button>
            </form>
            <p className='my-5'>
              Remember Your Account? <Link to={"../login"} className='hover:text-red-700 text-blue-700 hover:underline'>Login</Link>
            </p>
          </div>
        </div>
      </section>
      {/* <Routes>
        <Route path="/" element={<Footer />} />
      </Routes> */}
    </div>
  );
}

export default ForgotPassword;