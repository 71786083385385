import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import productCategory from '../helpers/productCategory';
import VerticalCard from '../components/VerticalCard';
import SummaryApi from '../common';

const CategoryProduct = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const urlCategoryListinArray = urlSearch.getAll('category');

  const urlCategoryListObject = {};
  urlCategoryListinArray.forEach(el => {
    urlCategoryListObject[el] = true;
  });

  const [selectCategory, setSelectCategory] = useState(urlCategoryListObject);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [sortBy, setSortBy] = useState('');

  const fetchData = async () => {
    const response = await fetch(SummaryApi.filterProduct.url, {
      method: SummaryApi.filterProduct.method,
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        category: filterCategoryList
      })
    });

    const dataResponse = await response.json();

    setData(dataResponse?.data || []);
  };

  const handleSelectCategory = (e) => {
    const { value, checked } = e.target;

    setSelectCategory((prev) => {
      return {
        ...prev,
        [value]: checked
      };
    });
  };

  useEffect(() => {
    fetchData();
  }, [filterCategoryList]);

  useEffect(() => {
    const arrayOfCategory = Object.keys(selectCategory).map(categoryKeyName => {
      if (selectCategory[categoryKeyName]) {
        return categoryKeyName;
      }
      return null;
    }).filter(el => el);

    setFilterCategoryList(arrayOfCategory);
    const urlformat = arrayOfCategory.map((el, index) => {
      if ((arrayOfCategory.length - 1) === index) {
        return `category=${el}`;
      }
      return `category=${el}&&`;
    });
    navigate('/product-category?' + urlformat.join(''));
  }, [selectCategory]);

  const handleOnChangeSortBy = (e) => {
    const { value } = e.target;
    setSortBy(value);

    if (value === 'asc') {
      setData(prev => prev.sort((a, b) => a.sellingPrice - b.sellingPrice));
    }

    if (value === 'dsc') {
      setData(prev => prev.sort((a, b) => b.sellingPrice - a.sellingPrice));
    }
  };

  return (
    <div className='container w-full max-w-[2560px] mx-auto p-4'>
      {/* Filter menu for mobile */}
      <div className='lg:hidden flex flex-col gap-2 mb-4'>
        <div className='flex justify-between items-center overflow-x-auto'>
          <button className='bg-gray-200 px-4 py-2 rounded' onClick={() => document.getElementById('sortDropdown').classList.toggle('hidden')}>Sort by</button>
          <button className='bg-gray-200 px-4 py-2 rounded' onClick={() => document.getElementById('categoryDropdown').classList.toggle('hidden')}>Category</button>
        </div>
        <div id='sortDropdown' className='hidden bg-white p-2'>
          <div className='flex flex-col'>
            <div className='flex items-center gap-3'>
              <input type='radio' name='sortBy' checked={sortBy === 'asc'} value='asc' onChange={handleOnChangeSortBy} />
              <label>Price - Low to High</label>
            </div>
            <div className='flex items-center gap-3'>
              <input type='radio' name='sortBy' checked={sortBy === 'dsc'} value='dsc' onChange={handleOnChangeSortBy} />
              <label>Price - High to Low</label>
            </div>
          </div>
        </div>
        <div id='categoryDropdown' className='hidden bg-white p-2'>
          <div className='flex flex-col'>
            {productCategory.map((categoryName, index) => {
              return (
                <div className='flex items-center gap-3 py-1' key={index}>
                  <input
                    type='checkbox'
                    name='category'
                    checked={selectCategory[categoryName?.value]}
                    value={categoryName?.value}
                    id={categoryName?.value}
                    onChange={handleSelectCategory}
                  />
                  <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* desktop version */}
      <div className='hidden lg:grid grid-cols-[200px,1fr]'>
        {/* left side filter */}
        <div className='bg-white p-2 min-h-[calc(100vh-120px)] overflow-y-scroll'>
          {/* sort by */}
          <div className=''>
            <h3 className='text-lg font-medium text-slate-500 text-base uppercase border-b-2 pb-1 border-slate-300'>Sort by</h3>
            <form className='text-sm flex-col gap-2 py-2'>
              <div className='flex items-center gap-3'>
                <input type='radio' name='sortBy' checked={sortBy === 'asc'} value='asc' onChange={handleOnChangeSortBy} />
                <label>Price - Low to High</label>
              </div>
              <div className='flex items-center gap-3'>
                <input type='radio' name='sortBy' checked={sortBy === 'dsc'} value='dsc' onChange={handleOnChangeSortBy} />
                <label>Price - High to Low</label>
              </div>
            </form>
          </div>

          {/* filter by */}
          <div className=''>
            <h3 className='text-lg font-medium text-slate-500 text-base uppercase border-b pb-1 border-slate-300'>Filter By Category</h3>
            <form className='text-sm flex-col gap-2 py-2'>
              {productCategory.map((categoryName, index) => {
                return (
                  <div className='flex items-center gap-3 py-1' key={index}>
                    <input
                      type='checkbox'
                      className=''
                      name='category'
                      checked={selectCategory[categoryName?.value]}
                      value={categoryName?.value}
                      id={categoryName?.value}
                      onChange={handleSelectCategory}
                    />
                    <label htmlFor={categoryName?.value}>{categoryName?.label}</label>
                  </div>
                );
              })}
            </form>
          </div>
        </div>

        {/* right side product */}
        <div className='px-4'>
          <p className='font-medium text-lg my-2 text-slate-500'>Search Results: {data.length}</p>
          <div className='min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]'>
            {data.length !== 0 && <VerticalCard data={data} loading={loading} />}
          </div>
        </div>
      </div>

      {/* Products for mobile */}
      <div className='lg:hidden'>
        <p className='font-medium text-lg my-2 text-slate-500'>Search Results: {data.length}</p>
        <div className='min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]'>
          {data.length !== 0 && <VerticalCard data={data} loading={loading} />}
        </div>
      </div>
    </div>
  );
};

export default CategoryProduct;
