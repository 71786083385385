import React from 'react'
import { Link } from 'react-router-dom'
import cancelIcon from '../assets/icons/R2.gif'

const PaymentCancel = () => {
  return (
    <div className='bg-slate-200 w-full max-w-md mx-auto flex flex-col mix-blend-multiply my-2 p-5 rounded  justify-center items-center'>
        <img
        src={cancelIcon}
        width={250}
        height={250}
        className='mix-blend-multiply'
        
        />
        <p className='text-red-500 mb-2 font-bold text-xl'>Payment Cancelled!</p>
        <Link to={"/cart"} className='p-2 bg-orange-500 hover:bg-white border-2 border-orange-500 hover:text-orange-500 rounded text-sm font-bold text-white'>Go to Cart</Link>
    </div>
  )
}


export default PaymentCancel