import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SummaryApi from '../common'
import { toast } from 'react-toastify'
import '../App.css';
import { CiEdit } from "react-icons/ci";
import moment from 'moment'
import ChangeUserRole from '../components/ChangeUserRole'

const AllUsers = () => {

const[allUser,setAllUsers] = useState([])
const [openUpdateRole, setOpenUpdateRole] = useState(false)
const [updateUserDetails, setUpdateUserDetails] = useState({
    email : "",
    name : "",
    location : "",
    role : "",
    _id : ""

})

const fetchAllUsers = async() => {
    const fetchData = await fetch(SummaryApi.allUser.url,{
        method : SummaryApi.allUser.method,
        credentials : 'include'
    })
const dataResponse = await fetchData.json()


if (dataResponse.success) {
    setAllUsers(dataResponse.data)
}
if (dataResponse.error) {
    toast.error(dataResponse.message)
}
// console.log(dataResponse)
}

useEffect(()=>{
fetchAllUsers()
},[])
  return (
    <div className='bg-white pb-4 '>
    <table className='w-full userTable'>
        <thead>
          <tr className='bg-orange-400 text-white'>
          <th>Sr. </th>
            <th>Name</th>
            <th>Email</th>
            <th>Location</th>
            <th>Role</th>
            <th>Created Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className='' >
            {
                allUser.map((el,index)=> {
                    return(
                        <tr>
                            <td>{index+1}</td>
                            <td>{el?.name}</td>
                            <td>{el?.email}</td>
                            <td>{el?.location}</td>
                            <td>{el?.role}</td>
                            <td>{moment(el?.createdAtc).format('LL')}</td>
                            <td>
                                <button className='bg-green-100 p-2 rounded-full cursor-pointer hover:bg-green-500' 
                                onClick={()=>{
                                    setUpdateUserDetails(el)
                                    setOpenUpdateRole(true)
                                }}
                                
                                ><CiEdit /></button>
                            </td>
                            </tr>
                    )
                })
            }
        </tbody>
    </table>

{
    openUpdateRole && (
        <ChangeUserRole 
        onClose={()=>setOpenUpdateRole(false)} 
        name={updateUserDetails.name}
        email={updateUserDetails.email}
        location={updateUserDetails.location}
        role={updateUserDetails.role}
        userId = {updateUserDetails._id}
        callFunc = {fetchAllUsers}
        />
    )
}

       
       

  
    </div>
  )
}

export default AllUsers