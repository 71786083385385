import React, { useEffect, useState } from 'react';
import { MdDelete, MdModeEdit } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayTZSCurrency from '../helpers/DisplayCurrency';
import SummaryApi from '../common';

const AdminProductCard = ({ data, fetchdata }) => {
  const [editProduct, seteditProduct] = useState(false);
  const [MyProducts, setAllProduct] = useState([]);

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.MyProducts.url, {
      method: SummaryApi.MyProducts.method,
      credentials: 'include',
      headers: {
        "content-type": 'application/json'
      }
    });
    const responseData = await response.json();
    if (responseData.success) {
      setAllProduct(responseData.data);
    }
  }

  const deleteProduct = async (id) => {
    const response = await fetch(SummaryApi.deleteProduct.url, {
      method: SummaryApi.deleteProduct.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ _id: id })
    });
    const responseData = await response.json();
    if (responseData.success) {
      // Directly update MyProducts state to reflect the deletion
      setAllProduct(prevProducts => prevProducts.filter(product => product._id !== id));
    }
  }

  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <div className='bg-white p-3 rounded '>
      <div className='w-40 '>
        <div className='w-32 h-32 justify-center items-center'>
          <img src={data?.productImage[0]} className='mx-auto object-scale-down h-full' />
        </div>
        <p className='capitalize'>stock : {data?.stock}</p>
        <p className='capitalize'>seller : {data?.userId?.name}</p>
        <h1 className='text-ellipsis line-clamp-1'>{data.productName}</h1>

        <div>
          <div className='font-semibold'>
            {displayTZSCurrency(data.sellingPrice)}
          </div>
         <div className='flex items-center justify-between'>
         <div onClick={() => deleteProduct(data?._id)} className='w-fit bg-red-300  text-white rounded-full p-2 text-xl cursor-pointer hover:bg-red-600 hover:text-white' title='Delete Product'>
            <MdDelete />
          </div>
          <div className='w-fit  p-2.5 bg-green-300 cursor-pointer hover:bg-green-700 rounded-full text-white' title='Update Product' onClick={() => seteditProduct(true)}>
            <MdModeEdit />
          </div>
         </div>
        </div>
      </div>
      {editProduct && (
        <AdminEditProduct productData={data} onClose={() => seteditProduct(false)} fetchdata={fetchdata} />
      )}
    </div>
  )
}

export default AdminProductCard;
