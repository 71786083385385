import React from 'react'
import displayTZSCurrency from '../helpers/DisplayCurrency';
import { Navigate, useNavigate } from 'react-router-dom';

const Subscription = () => {
  const navigate = useNavigate();

    const plans = [
        {
          name: 'Basic',
          description: 'Great for starters',
          amount: '13500',
          services: [
            'Ads push through website',
            'First three listed product',
            'win Arushajiji Posters',
            'Basic customer support'
          ],
        },
        {
          name: 'Premium',
          description: 'Perfect for growing businesses',
          amount: '23500',
          services: [
            'Ads push through website',
            'First six listed products',
            'win Arushajiji Posters',
            'Priority customer support',
            'Customizable marketing campaigns',
            'Monthly sales reports'
          ],
        },
        {
          name: 'Extreme',
          description: 'Best for established businesses',
          amount: '43500',
          services: [
            'Ads push through website',
            'First twenty listed products',
            'win Arushajiji Posters',
            'win Arushajiji Banners',
            '24/7 customer support',
            'Customizable marketing campaigns',
            'Bi-weekly sales reports',
            'Ads push through social media'
            
          ],
        },
        {
          name: 'Extremely Super',
          description: 'Ultimate plan for maximum growth',
          amount: '83500',
          services: [
            'Ads push through website',
            'Unlimited First listed products',
            'Ads push through social media',
            'win Arushajiji Posters',
            'win Arushajiji Banners',
            'Free delivery',
            'Dedicated account manager',
            'Weekly sales reports',
            'Advanced analytics',
            'Premium customizable marketing campaigns'
          ],
        }
      ];
      const handleSubscribe = (plan) => {
        navigate('/payment', { state: { plan } });
      };
    
      return (
        <div className="bg-gray-100 py-8 h-[calc(100vh-90px)] overflow-y-scroll">
          <div className="container mx-auto px-1">
            <h1 className="text-3xl font-bold text-center mb-8">Subscription Plans</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
              {plans.map((plan) => (
                <div key={plan.name} className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="p-6">
                    <h2 className="text-2xl font-bold text-orange-600  border-b mb-4">{plan.name}</h2>
                    <p className="text-gray-700 font-semibold mb-4">{plan.description}</p>
                    <p className="mb-4 text-orange-600"><b className="text-2xl font-bold mb-4">{displayTZSCurrency(plan.amount)}</b> <b className="text-sm font-medium">/ month</b> </p>
                    <ul className="mb-6">
                      {plan.services.map((service, index) => (
                        <li key={index} className="flex items-center mb-2">
                          <span className="text-orange-500 mr-2">&#10003;</span>
                          {service}
                        </li>
                      ))}
                    </ul>
                    <button  onClick={() => handleSubscribe(plan)} className="w-full bg-orange-500 hover:bg-orange-700 hover:scale-105 font-semibold transition-all text-white py-2 rounded">Subscribe</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

export default Subscription