import React, { useContext, useEffect, useRef, useState } from 'react'
import { Await, Link } from 'react-router-dom'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayTZSCurrency from '../helpers/DisplayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import addToCart from '../helpers/addToCart'
import Context from '../context'

const HorizontalCardproduct = ({ category, heading }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)

    const [scroll,setScroll] = useState(0)
    const scrollElement = useRef()


    const {fetchUserAddToCart} = useContext(Context)

    const handleAddToCart = async(e,id) =>{
       await addToCart(e,id) 
    fetchUserAddToCart()
    }

    const fetchData = async () => {
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category)
        setLoading(false)
        console.log("horizontal data",categoryProduct.data)

        setData(categoryProduct?.data)
    }


    useEffect(() => {
        fetchData()
    }, [])
    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300
    }
    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300
    }
    return (
        <div className='container w-full max-w-[2560px] mx-auto px-4 my-6 relative'>

            <h2 key={heading} className='text-2xl font-semibold py-4'>{heading}</h2>
            {/* <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6 lg:gap-24 overflow-auto scrollbar-none" ref={scrollElement}>  */}{/* uncomment this for grid view */}
            <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none transition-all' ref={scrollElement}>

            <button  className='bg-white shadow-md rounded-full p-1 absolute left-0 text-lg hidden md:block ' onClick={scrollLeft}> <FaAngleLeft /></button>{/* comment this for grid view */}
            <button  className='bg-white shadow-md rounded-full p-1 absolute right-0 text-lg hidden md:block ' onClick={scrollRight}><FaAngleRight /></button> {/* comment this for grid view */}
                {
                    loading ? (
                        loadingList.map((product, index) => {
                            return (
                                <div className='w-full min-w-[320px] md:min-w-[320px] max-w-[350px] md:max-w-[320px] h-44 bg-white rounded-sm shadow-md flex' key={index}>
                                    <div className='h-full bg-slate-200 p-4 min-w-[90px] md:min-w-[120px] animate-pulse'>
    
                                    </div>
                                    <div className='p-4 grid w-full gap-2'>
                                        <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 bg-slate-200 animate-pulse rounded-full'></h2>
                                        {/* <p className='capitalize text-ellipsis line-clamp-1 text-slate-500'></p> */}
                                        <p className='text-ellipsis line-clamp-1 mb-2 text-sm p-1 animate-pulse rounded-full bg-slate-200'></p>
                                        <div className='flex gap-3 w-full'>
                                            <p className='text-orange-500 w-full font-semibold p-1 animate-pulse rounded-full bg-slate-200'></p>
                                            <p className='text-slate-500 w-full  text-sm line-through animate-pulse rounded-full p-1 bg-slate-200'></p>
                                        </div>
                                        <button className='text-sm text-white px-3 py-0.5 font-semibold animate-pulse rounded-full w-full bg-slate-200'></button>
                                    </div>
                                </div>
                            )
                        })                    ) : (
                        data?.map((product, index) => {
                            return (
                                <Link to={"product/"+product?._id} key={index} className='w-full min-w-[320px] md:min-w-[320px] max-w-[350px] md:max-w-[320px] h-48 bg-white rounded-sm shadow-md flex'>
                                    <div className='h-full bg-slate-200 p-4 min-w-[90px] md:min-w-[120px]'>
                                        <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply  ' />
    
                                    </div>
                                    <div className='p-4 grid w-full'>
                                        <h2 className='font-medium text-base md:text-lg relative text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                                        <p className='capitalize text-ellipsis line-clamp-1 text-slate-500'>{product?.category}</p>
                                        <p className='text-ellipsis line-clamp-1 mb-2 text-sm'>{product?.userId?.name}</p>
                                        <div className='grid'>
                                            <p className='text-orange-500  font-semibold'>{displayTZSCurrency( product?.sellingPrice)}</p>
                                            <p className='text-slate-500 text-sm line-through p-1'>{displayTZSCurrency(product?.price)}</p>
                                        </div>
                                        <button onClick={(e)=>handleAddToCart(e,product?._id)} className=' text-sm bg-orange-600 max-w-[150px] hover:bg-orange-700 text-white px-3 rounded py-0.5 font-semibold'>Add to Cart</button>
                                    </div>
                                </Link>
                            )
                        })
                    )
              
                }
            </div>

        </div>
    )
}

export default HorizontalCardproduct