import React, { useContext } from 'react'
import scrollTop from '../helpers/scrollTop'
import displayTZSCurrency from '../helpers/DisplayCurrency'
import Context from '../context'
import addToCart from '../helpers/addToCart'
import { Link } from 'react-router-dom'

const VerticalCard = ({loading,data = []}) => {
    const loadingList = new Array(13).fill(null)

    const {fetchUserAddToCart} = useContext(Context)

    const handleAddToCart = async(e,id) =>{
       await addToCart(e,id) 
    fetchUserAddToCart()
    }
    
  return (
    <div className= 'grid grid-cols-[repeat(auto-fit,minmax(300px,310px))] mx-auto justify-center md:justify-between md:gap-6 overflow-x-scroll scrollbar-none transition-all'>


    {
        loading ? (
            loadingList.map((product, index) => {
                return (
                    <div className='w-full min-w-[280px] md:min-w-[300px] max-w-[280px] md:max-w-[310px] bg-white rounded-sm shadow'>
                        <div className='h-48 bg-slate-200 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center p-1 py-2'>
                            {/* <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply ' /> */}

                        </div>
                        <div className='p-4 grid gap-3'>
                            <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 rounded-full animate-pulse bg-slate-200 p-1 py-2'></h2>
                            <p className='capitalize text-ellipsis line-clamp-1 text-slate-500 animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
                            <p className='text-ellipsis line-clamp-1  text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
                            <div className='flex gap-3'>
                                <p className='text-orange-600  font-semibold text-lg animate-pulse w-full rounded-full bg-slate-200 p-1 py-2'></p>
                                <p className='text-slate-500 text-sm line-through p-1 animate-pulse w-full rounded-full bg-slate-200 p-1 py-2'></p>
                            </div>
                            {/* <div className='flex justify-center items-center '> */}
                            <button className='text-sm text-white px-3 rounded py-2 font-semibold animate-pulse rounded-full bg-slate-200'></button>

                            {/* </div>  */}
                        </div>
                    </div>
                )
            })                    ) : (
                
            data.map((product, index) => {
                return (
                    <Link to={"/product/"+product?._id} className='w-full min-w-[320px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow' onClick = {scrollTop}>
                        <div className='h-48 bg-slate-200 p-4 min-w-[200px] md:min-w-[145px] flex justify-center items-center'>
                            <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply ' />

                        </div>
                        <div className='p-4 grid gap-3'>
                            <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                            <div className='flex'>
                                        <p className='capitalize text-ellipsis line-clamp-1 text-slate-500'>{product?.category}</p>
                                        <p className='capitalize px-3 text-ellipsis line-clamp-1 text-slate-400'>{product?.condition}</p>
                                        </div>
                            <p className='text-ellipsis line-clamp-1  text-sm'>{product?.description}</p>
                            <div className='flex gap-2'>
                                <p className='text-orange-600  font-semibold text-lg'>{displayTZSCurrency( product?.sellingPrice)}</p>
                                <p className='text-slate-500 text-sm line-through p-1'>{displayTZSCurrency(product?.price)}</p>
                            </div>
                            <div className='flex justify-center items-center '>
                            <button onClick={(e)=>handleAddToCart(e,product?._id)} className='text-md bg-orange-600 min-w-[200px] hover:bg-orange-700 text-white px-3 rounded py-0.5 font-semibold'>Add to Cart</button>

                            </div> 
                        </div>
                    </Link>
                )
            })
        )
    
    }
</div>
  )
}

export default VerticalCard