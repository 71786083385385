import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import UploadProduct from '../components/UploadProduct'
import SummaryApi from '../common'
import AdminProductCard from '../components/AdminProductCard'

const AllProducts = () => {
  const [openUploadproduct, setOpenUploadProduct] = useState(false)
  const [allProduct,setAllProduct] = useState([])

  const fetchAllProduct = async() => {
    const response = await fetch(SummaryApi.allProduct.url)
    const dataResponse = await response.json()


    setAllProduct(dataResponse?.data || [])
  }
  useEffect(()=>{
    fetchAllProduct()
  },[])
  
  return (
    <div>
  <div className='bg-white py-2 px-4 flex justify-between items-center'>
<h2 className='font-bold text-lg'>All Products</h2>
<button className='border-2 border-orange-400 text-orange-400 hover:border-orange-400 hover:bg-orange-400 hover:text-white py-2 px-4 rounded-full' onClick={()=>setOpenUploadProduct(true)}>Upload Products</button>
  </div>
{/* all Products */}
<div className='flex flex-wrap items-center gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll'>
{
  allProduct.map((product,index)=>{
    return(
      <AdminProductCard data={product} key={index+"allProduct"} fetchdata={fetchAllProduct}/>
      
    )
  })
}
</div>



        {/*upload products component */}
  
  
  {
    openUploadproduct && (
<UploadProduct onClose = {()=>setOpenUploadProduct(false)} fetchData={fetchAllProduct}/>
    )
  }
    </div>
  )
}

export default AllProducts