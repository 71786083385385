import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import imageTobase64 from "../helpers/imageTobase64";
import '../App.css';
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import icons8user from '../assets/icons/icons8-user.gif';
import SummaryApi from '../common';
import tanzaniaCities from '../helpers/tanzaniaCities';
const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
    confirmPassword: "",
    profilePic: "",
    registerAsSeller: false,
    location: ""
  });

  const navigate = useNavigate()

  const handleOnChange = async (e) => {
    const { name, value, type, checked } = e.target;
    setData((preve) => {
      return {
        ...preve,
        [name]: type === 'checkbox' ? checked : value
        // [name]: value
      }
    })
  }

  const handleUploadPic = async (e) => {
    const file = e.target.files[0];
    const imagePic = await imageTobase64(file);
    setData((preve) => {
      return {
        ...preve,
        profilePic: imagePic
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.password === data.confirmPassword) {
      try {
        const dataResponse = await fetch(SummaryApi.signUp.url, {
          method: SummaryApi.signUp.method,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const dataApi = await dataResponse.json();
        if (dataApi.success) {
          toast.success(dataApi.message)
          navigate("/login")
        }
        if (dataApi.error) {
          toast.error(dataApi.message)
          // navigate("/login")
        }

      } catch (error) {
        toast("An error occurred. Please try again.");
        console.error("Error:", error);
      }
    } else {
      toast.error("The passwords do not match");
      console.log("The passwords do not match");
    }
  };

  return (
    <div>
      {/* <Routes>
        <Route path="/" element={<Header />} />
      </Routes> */}
      <section id='login'>
        <div className='mx-auto container p-4'>
          <div className='bg-white p-5 w-full max-w-sm mx-auto'>
            <div className='w-20 h-20 mx-auto relative overflow-hidden rounded-full'>
              <div>
                <div className={!data.profilePic ? "w-35 h-24 object-contain" : ""}>
                  <img
                    src={data.profilePic |<label>
                  <div className='text-xs cursor-pointer bg-opacity-80 bg-slate-200 pb-4 pt-2 text-center absolute bottom-0 w-full'>
                    Upload Profile
                  </div>
                  <input type="file" className='hidden' onChange={handleUploadPic} />
                </label>| icons8user}
                    alt="Login Icon"
                    className={!data.profilePic ? "w-full h-full" : ""}
                  />
                </div>
              </div>
              <form>
                
              </form>
            </div>
            <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
              <div className='grid'>
                <label>Full Name / Business Name</label>
                <div className='bg-slate-100 p-2'>
                  <input
                    type='text'
                    required
                    value={data.name}
                    onChange={handleOnChange}
                    name='name'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Full Name'
                  />
                </div>
              </div>
              <div className='grid'>
                <label>Email:</label>
                <div className='bg-slate-100 p-2'>
                  <input
                    type='email'
                    required
                    value={data.email}
                    onChange={handleOnChange}
                    name='email'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='email address'
                  />
                </div>
              </div>
              <div className='grid'>
                <label>Password:</label>
                <div className='bg-slate-100 p-2 flex'>
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    name='password'
                    onChange={handleOnChange}
                    value={data.password}
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Password'
                  />
                  <div className='cursor-pointer text-xl' onClick={() => setShowPassword((prev) => !prev)}>
                    <span>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <div className='grid'>
                <label>Confirm Password:</label>
                <div className='bg-slate-100 p-2 flex'>
                  <input
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    name='confirmPassword'
                    onChange={handleOnChange}
                    value={data.confirmPassword}
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Confirm Password'
                  />
                  <div className='cursor-pointer text-xl' onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    <span>
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex gap-2'>
                <label className='font-bold text-blue-400'>Register As Seller ?</label>
                <div className=' p-2 flex'>
                  <input
                    type='checkbox'
                    name='registerAsSeller'
                    onChange={handleOnChange}
                    value={data.registerAsSeller}
                    className=' form-checkbox cursor-pointer bg-transparent h-4 w-4 text-blue-600 border-1 border-blue-500 rounded-sm mr-2'
                    title='checking this, you will be provided with sellers dashboard'
                  />
                  {/* <div className='cursor-pointer text-xl' onClick={() => setShowConfirmPassword((prev) => !prev)}>
                    <span>
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div> */}
                </div>
              </div>
              <div className='grid'>
                <label>Location</label>
                {/* <div className='bg-slate-100 p-2'> */}
                <select value={data.location} required name='location' onChange={handleOnChange} className='p-2 bg-slate-100 border outline-none rounded'>
                  <option value="">--Select Your Location--</option>

                  {

                    tanzaniaCities.map((el, index) => {
                      return (
                        <option value={el.value} key={el.value + index}>{el.value}</option>
                      )
                    })
                  }

                </select>

                {/* </div> */}
              </div>
              <button className='hover:bg-blue-600 bg-orange-600 text-white px-5 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                Sign Up
              </button>
            </form>
            <p className='my-5'>
              already have account? <Link to={"../login"} className='hover:text-red-700 text-blue-700 hover:underline'>Login</Link>
            </p>
          </div>
        </div>
      </section>
      {/* <Routes>
        <Route path="/" element={<Footer />} />
      </Routes> */}
    </div>
  );
}

export default SignUp;