import React, { useContext, useEffect, useRef, useState } from 'react'
import { Await, Link } from 'react-router-dom'
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct'
import displayTZSCurrency from '../helpers/DisplayCurrency'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'
import addToCart from '../helpers/addToCart'
import Context from '../context'
import scrollTop from '../helpers/scrollTop'


const CategoryWiseProductDisplay = ({ category, heading }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const loadingList = new Array(13).fill(null)



    const {fetchUserAddToCart} = useContext(Context)

    const handleAddToCart = async(e,id) =>{
       await addToCart(e,id) 
    fetchUserAddToCart()
    }

    // const handleLinkClick = () => { 
    //     window.location.reload(); // This will reload the page
    // };

    const fetchData = async () => {
        setLoading(true)
        const categoryProduct = await fetchCategoryWiseProduct(category)
        setLoading(false)
        // console.log("horizontal data",categoryProduct.data)

        setData(categoryProduct?.data)
    }


    useEffect(() => {
        fetchData()
    }, [])
  
    return (
        <div className='container w-full max-w-[2560px] mx-auto px-4 my-6 relative'>

            <h2 className='text-2xl font-semibold py-4 justify-center'>{heading}</h2>
            {/* <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6 lg:gap-24 overflow-auto scrollbar-none" ref={scrollElement}>  */}{/* uncomment this for grid view */}
            <div className= 'grid grid-cols-[repeat(auto-fit,minmax(220px,300px))] md:gap-10 justify-between justify-center overflow-x-scroll scrollbar-none transition-all'>


                {
                    loading ? (
                        loadingList.map((product, index) => {
                            return (
                                <div className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
                                    <div className='h-48 bg-slate-200 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center p-1 py-2'>
                                        {/* <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply ' /> */}
    
                                    </div>
                                    <div className='p-4 grid gap-3'>
                                        <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 rounded-full animate-pulse bg-slate-200 p-1 py-2'></h2>
                                        <p className='capitalize text-ellipsis line-clamp-1 text-slate-500 animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
                                        <p className='text-ellipsis line-clamp-1  text-sm animate-pulse rounded-full bg-slate-200 p-1 py-2'></p>
                                        <div className='flex gap-3'>
                                            <p className='text-orange-600  font-semibold text-lg animate-pulse w-full rounded-full bg-slate-200 p-1 py-2'></p>
                                            <p className='text-slate-500 text-sm line-through p-1 animate-pulse w-full rounded-full bg-slate-200 p-1 py-2'></p>
                                        </div>
                                        {/* <div className='flex justify-center items-center '> */}
                                        <button className='text-sm text-white px-3 rounded py-2 font-semibold animate-pulse rounded-full bg-slate-200'></button>
    
                                        {/* </div>  */}
                                    </div>
                                </div>
                            )
                        })                    ) : (
                            
                        data.map((product, index) => {
                            return (
                                <Link to={"/product/"+product?._id} className='w-full min-w-[320px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow' onClick = {scrollTop}>
                                    <div className='h-48 bg-slate-200 p-4 min-w-[200px] md:min-w-[145px] flex justify-center items-center'>
                                        <img src={product?.productImage[0]} className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply ' />
    
                                    </div>
                                    <div className='p-4 grid gap-3'>
                                        <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>{product?.productName}</h2>
                                        <div className='flex'>
                                        <p className='capitalize text-ellipsis line-clamp-1 text-slate-500'>{product?.category}</p>
                                        <p className='capitalize px-4 text-ellipsis line-clamp-1 text-slate-500'>{product?.condition}</p>

                                        </div>
                                        <p className='text-ellipsis line-clamp-1  text-sm'>{product?.description}</p>
                                        <div className='flex gap-2'>

                                            <p className='text-orange-600  font-semibold text-lg'>{displayTZSCurrency( product?.sellingPrice)}</p>
                                            <p className='text-slate-500 text-sm line-through p-1'>{displayTZSCurrency(product?.price)}</p>
                                        </div>
                                        <div className='flex justify-center items-center '>
                                        <button onClick={(e)=>handleAddToCart(e,product?._id)} className='text-md bg-orange-600 min-w-[200px] hover:bg-orange-700 text-white px-3 rounded py-0.5 font-semibold'>Add to Cart</button>
    
                                        </div> 
                                    </div>
                                </Link>
                            )
                        })
                    )
                
                }
            </div>

        </div>
    )
}

export default CategoryWiseProductDisplay