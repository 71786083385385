import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import displayTZSCurrency from '../helpers/DisplayCurrency';

const PaymentPage = ({ onPaymentSuccess }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state;

  const handlePaymentSuccess = async () => {
    // Mock payment success
    const paymentResult = { success: true };

    if (paymentResult.success) {
      await onPaymentSuccess(plan);
    }
  };

  return (
    <div className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold text-center mb-8">Subscribe for {plan.name}</h1>
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden p-6">
          <p className="text-xl mb-4">Amount:  <b>{displayTZSCurrency(plan.amount)}</b></p>
          <button
            onClick={handlePaymentSuccess}
            className="w-full bg-orange-500 font-medium text-white py-2 rounded"
          >
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
