import React from 'react'

const productCondition = [
    {id : 1, label : "Brand New", value : "Brand New"},
    {id : 2, label : "Refurbished", value : "Refurbished"},
    {id : 3, label : "Used", value : "Used"},
   

    
    


    
]
 


export default productCondition