import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { FaStar } from "react-icons/fa";
import { FaStarHalf } from "react-icons/fa";
import displayTZSCurrency from '../helpers/DisplayCurrency';
// import VerticalCardproduct from '../components/VerticalCardproduct';
import CategoryWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import { FaRegStarHalfStroke } from "react-icons/fa6";





const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    condition: ""
  })

  const params = useParams()

  const [loading, setLoading] = useState(true)
  const productListImagesLoading = new Array(4).fill(null)
  const [activeImage, setActiveImage] = useState("")
  const navigate = useNavigate()
  const { fetchUserAddToCart } = useContext(Context)

  const handleAddToCart = async (e, id) => {
    await addToCart(e, id)
    fetchUserAddToCart()
  }


  const handleBuyProduct = async(e,id) => {
    await addToCart(e, id)
    fetchUserAddToCart()
    navigate("/cart")
  }

  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
    x: 0,
    y: 0
  })
  const [zoomImage, setZoomImage] = useState(false)




  const fetchProductDetails = async () => {
    setLoading(true)
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        productId: params?.id
      })
    })
    setLoading(false)
    const dataReponse = await response.json()

    setData(dataReponse?.data)
    setActiveImage(dataReponse?.data?.productImage[0])
  }

  

  useEffect(() => {
    fetchProductDetails()
  }, [params])
  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL)

  }
  const handleZoomImage = useCallback((e) => {
    setZoomImage(true)
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    console.log("coordinate", left, top, width, height);

    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    setZoomImageCoordinate({
      x,
      y
    });

  }, [setZoomImageCoordinate])

  const handleLeaveImageZoom = () => {
    setZoomImage(false)
  }

  return (
    <div className='container w-full max-w-[2560px] mx-auto p-4'>
      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
        {/* product image */}
        <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>

          <div className='relative bg-slate-200 h-[300px] w-[300px] lg:h-96 lg:w-96 h-96 w-96 p-2'>
            <img src={activeImage} className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} />
            {/* product image zoom */}

            {
              zoomImage && (
                <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[460px] bg-slate-200 p-1 -right-[510px] top-0'>
                  <div
                    className='w-full h-full min-h-[400px] min-w-[400px] mix-blend-multiply scale-150'
                    style={{
                      backgroundImage: `url(${activeImage})`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
                    }}
                  >

                  </div>
                </div>
              )
            }

          </div>
          <div className='h-full'>
            {
              loading ? (
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    productListImagesLoading?.map((el,index) => {
                      return (
                        <div className='h-20 w-20 animate-pulse bg-slate-200 rounded animate-pulse' key={"loadingImage"+index}>

                        </div>
                      )

                    })
                  }

                </div>
              ) : (
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    data?.productImage?.map((imgURL, index) => {
                      return (
                        <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                          <img src={imgURL} alt='' className='w-full h-full object-scale-down mix-blend-multiply cursor-pointe' onMouseEnter={() => handleMouseEnterProduct(imgURL)} onClick={() => handleMouseEnterProduct(imgURL)} />
                        </div>
                      )

                    })
                  }

                </div>
              )
            }
          </div>


        </div>
        {/*  product details*/}
        {
          loading ? (
            <div className='grid gap-1 w-full'>
              <p className='bg-slate-200 animate-pulse text-orange-600 h-6 lg:h-8 rounded-full inline-block w-full'></p>
              <h2 className='text-2xl lg:text-4xl font-medium h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></h2>
              <p className='capitalize text-slate-400 h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></p>
              <div className='text-red-600 flex items-center h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full gap-1'>


              </div>
              <div className='flex mt-2 items-center gap-2 my-1 w-full'>
                <p className=' text-2xl lg:text-3xl font-medium text-orange-600 h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'>  </p>
                <p className='text-xl lg:text-2xl text-slate-500 font-medium line-through h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'>  </p>

              </div>
              <div className='flex mt-2 items-center gap-3'>
                <button className='rounded px-3 py-1 min-w-[120px]  font-medium h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></button>
                <button className='rounded px-3 py-1 min-w-[120px] font-medium text-white h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></button>
              </div>
              <div className='my-2 w-full gap-2 mt-2'>
                <p className='h-6 lg:h-8 py-1 rounded-full animate-pulse bg-slate-200 w-full'></p>
                <p className='h-10 lg:h-12 py-1 mt-2 rounded-full animate-pulse bg-slate-200 w-full'></p>

              </div>

                       
            </div>
          ) : (
            <div className='flex flex-col gap-1'>
              <p className='bg-orange-200 text-orange-600 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
              <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
             <div className='flex'>
             <p className='capitalize text-slate-400'>{data?.category}</p>
             <p className='capitalize px-4 text-slate-400'><b>Condition : </b>{data?.condition}</p>

             </div>
             <p className='capitalize py-5 text-black'><b>In Stock : </b>{data?.stock} Available</p>

              <div className='text-red-600 flex items-center gap-1'>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaRegStarHalfStroke />

              </div>
              <div className='flex items-center gap-2 my-1'>
                <p className=' text-2xl lg:text-3xl font-medium text-orange-600'> {displayTZSCurrency(data.sellingPrice)} </p>
                <p className='text-xl lg:text-2xl text-slate-500 font-medium line-through'> {displayTZSCurrency(data.price)} </p>

              </div>
              <div className=' flex items-center gap-3'>
                <button onClick={(e) => handleBuyProduct(e, data?._id)} className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white'>Buy</button>
                <button onClick={(e) => handleAddToCart(e, data?._id)} className='border-2 border-orange-500 rounded px-3 py-1 min-w-[120px] text-orange-500 font-medium text-white bg-orange-500 hover:bg-white hover:text-orange-500'>Add To Cart</button>
              </div>
              <div className='my-2'>
                <p className='text-slate-600 font-medium my-1'>Description</p>
                <p>{data?.description}</p>
                
              </div>

              
            </div>
             

          )
        }
        
      </div>
      <div className='min-h-[200px] grid max-w-[300px] lg:max-w-[750px] p-4 mt-3'>
              <h1 className='text-xl lg:text-2xl font-medium border-b pb-1 border-slate-300'>Seller Information </h1>
              <p className='text-lg lg:text-xl font-medium'>Seller <b className='capitalize text-orange-600'>{data?.userId?.name}</b></p>
              <p className='text-lg lg:text-xl font-medium'>Email Address <b className='text-blue-600'><a href={`mailto:${data?.userId?.email}`}>{data?.userId?.email}</a></b></p>
              <p className='text-lg lg:text-xl font-medium'>Location <b className='capitalize text-orange-600'>{data?.userId?.location ||'Not Provided'}</b></p>

              </div>
      {
        data.category && (
          <CategoryWiseProductDisplay category={data?.category} heading={"Recommended Product"} />

        )
      }


    </div>

  )
}

export default ProductDetails