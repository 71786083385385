import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Link, Route, Routes, Outlet, useNavigate } from 'react-router-dom'
import { FaRegCircleUser } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import AllProducts from './AllProducts'
import AllUsers from './AllUsers'
import ROLE from '../common/role'

const SellerPanel = () => {
    const user = useSelector(state => state?.user?.user)

    const navigate =useNavigate()
useEffect(()=>{
if (user?.role === ROLE.SELLER) {
  
}else{
  navigate("/")
}
},[user])

    return (
        <div>
         {/* <Routes>
            <Route path="/" element={<Header />} />
          </Routes>     */}
            <div className='min-h-[calc(100vh-120px)]  md:flex grid w-full'>
    
            
          <aside className='bg-white min-h-full w-full md:max-w-60 md:grid flex gap-5 md:border md:border-l md:border-slate-300 customShadow'>
         <div className='h-32  flex justify-center items-center flex-col '>
          <div className='text-5xl cursor-pointer relative px-5 flex justify-center' >
            {
              user?.profilePic ? (
                <img src={user?.profilePic} className='w-20 h-20 rounded-full'alt={user?.name} />
              ):(
                <FaRegCircleUser />
              )
            }
          
          </div> 
          <p className='capitalize  text-lg font-semibold'>{ user?.name }</p>
          <p className='text-sm  text-slate-400'>My Account</p>
          </div>
          <div>
    
    {/* navigation */}
    
    <nav className='md:grid p-4 flex mt-7 lg:mt-[-200px]'>
    <Link to={"/"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>Home</Link>
      
        <Link to={"my-products"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>My Products</Link>
        <Link to={"all-orders"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>All Orders</Link>
<p className='py-1'>        <Link to={"subscribe"} className='px-2 py-1 bg-orange-600 text-center text-white font-bold p-3 rounded w-full hover:bg-orange-500 hover:text-white'>Subscribe</Link>
</p>
    </nav>
          </div>
    
          </aside>
           
          <main className='w-full h-full p-2'>
         <Outlet/>
          </main>
    
          </div>
            {/* <Routes>
            <Route path="/" element={<Footer />} />
          </Routes> */}
        </div>
      )
}

export default SellerPanel