import React, { useEffect, useState } from 'react'
import SummaryApi from '../common'
import { Link } from 'react-router-dom'
import './style.css'; // Make sure to import the CSS file
import congratsGif from '../assets/gif/congratulations.gif'; // Import the congratulatory GIF


const CategoryList = () => {
    const [categoryProduct, setCategoryProduct] = useState([])
    const [loading, setLoading] = useState(false)

    const categoryLoading = new Array(13).fill(null)

    const fetchCetgoryProduct = async () => {
        setLoading(true)
        const response = await fetch(SummaryApi.categoryProduct.url)
        const dataRepsonse = await response.json()
        setLoading(false)
        setCategoryProduct(dataRepsonse.data)
    }
    useEffect(() => {
        fetchCetgoryProduct()
    }, [])
    return (
        <div className='container w-full text-center max-w-[2560px] mx-auto p-4'>
          <b className='py-5'>Category List</b>  
            <div className='grid grid-cols-4 md:grid-cols-4 lg:flex lg:items-center lg:gap-4 justify-between overflow-scroll scrollbar-none'>
                {

                    loading ? (

                        categoryLoading.map((el, index) => {
                            return (
                                <div className='h-18 w-18 md:h-20 rounded-full overflow-hidden bg-slate-200 animate-pulse' key={"categoryLoading" + index}>

                                </div>
                            )
                        })
                    ) :
                        (
                            categoryProduct?.map((product, index) => {
                                return (
                                    <div className="marquee" key={index}>
                                    <div className="marquee-content">
                                      
                                        <Link to={`/product-category?category=${product?.category}`} className="cursor-pointer text-center py-5" key={index}>
                                          <div className="flex flex-col items-center">
                                            <div className="md:w-20 md:h-20 h-16 w-16 rounded-full p-3 bg-slate-200 overflow-hidden">
                                              <img src={product?.productImage[0]} alt={product?.category} className="h-full object-cover rounded-full mix-blend-multiply hover:scale-125 transition-all" />
                                            </div>
                                           
                                            <p className="mt-2 text-sm md:text-base capitalize text-ellipsis line-clamp-1">{product?.category}</p>
                                            
                                          </div>
                                        </Link>
                                    </div>
                                    
                                  </div>
                                )
                            })
                        )

                }
            </div>
            {/* <div className="congrats-message">
          🎉 Congratulations! 🎉
          <img src={congratsGif} alt="Congratulations" className="congrats-gif" />
        </div> */}
        </div>
    )
}

export default CategoryList