import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home'; // Import the Home component from its correct path
// import Header from '../components/Header';
// import Footer from '../components/Footer';
import Login from '../pages/Login'
import ForgotPassword from '../pages/ForgotPassword';
import SignUp from '../pages/SignUp';
import AdminPanel from '../pages/AdminPanel';
import AllUsers from '../pages/AllUsers';
import AllProducts from '../pages/AllProducts';
import CategoryProduct from '../pages/CategoryProduct';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import SearchProduct from '../pages/SearchProduct';
import SellerPanel from '../pages/SellerPanel';
import MyProducts from '../pages/MyProducts';
import Payment from '../payment/payment';
import CheckoutPage from '../pages/CheckoutPage';
import PaymentCancel from '../pages/PaymentCancel';
import Paymentsuccessing from '../pages/Paymentsuccessing';
import OrderPage from '../pages/OrderPage';
import AllOrder from '../pages/AllOrder';
import PaymentForm from '../components/PaymentForm';
import MyProfile from '../pages/MyProfile';
import Profile from '../pages/Profile';
import Subscription from '../pages/Subscription';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import SellerOrder from '../pages/SellerOrder';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        // element : <ToastContainer />,
        // element: <Header />,
        element: <Home />,
        // element:<Footer/>
      },
      {
        path: "login",
        // element: <Header/>,
        element: <Login />,
        // element: <Footer/>
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "sign-up",
        element: <SignUp />
      },
      {
        path: "product-category",
        element: <CategoryProduct />
      },

      {
        path: "product/:id",
        element: <ProductDetails />
      },
      {
        path: "cart",
        element: <Cart />
      },
      {
        path : 'payment-success',
        element: <Paymentsuccessing/>
      },
      {
        path : 'payment-cancel',
        element: <PaymentCancel/>
      },
      {
        path : 'MyOrders',
        element : <OrderPage/>
      },
      {
        path :  "search",
        element : <SearchProduct/>
      },
      {
        path :  "payment",
        element : <CheckoutPage/>
      },
      {
        path :  "MyProfile",
        element : <MyProfile/>
      },
      {
        path : "payment",
        element : <PaymentForm/>
      },
      
      {
        path : "privacy-policy",
        element : <PrivacyPolicy/>
      },
      {
        path: "admin-panel",
        element: <AdminPanel />,
        children: [
          {
            path: "all-users",
            element: <AllUsers />
          },
          {
            path: "my-products",
            element: <MyProducts />
          },
          {
            path: "all-products",
            element: <AllProducts />
          },
          {
            path : "all-orders",
            element : <AllOrder/>
          },
          {
            path :  "MyProfile",
            element : <MyProfile/>
          }
        ]
      },

      {
        path: "seller-panel",
        element: <SellerPanel />,
        children: [
          {
            path: "all-users",
            element: <AllUsers />
          },
          {
            path: "my-products",
            element: <MyProducts />
          },
          {
            path : "subscribe",
            element : <Subscription/>
          },
          {
            path :  "MyProfile",
            element : <MyProfile/>
          },
          {
            path : "all-orders",
            element : <SellerOrder/>
          },
        ]
      },
    

    ]
  },
]);

export default router;
