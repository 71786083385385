import React from 'react'
import successIcon from '../assets/icons/R1.gif'
import { Link } from 'react-router-dom'

const Paymentsuccessing = () => {
  return (
    <div className='bg-slate-200 w-full max-w-md mx-auto flex flex-col mix-blend-multiply my-2 p-4 rounded  justify-center items-center'>
        <img
        src={successIcon}
        width={250}
        height={250}
        className='mix-blend-multiply'

        
        />
        <p className='text-green-600 font-bold text-xl mb-2'>Payment successfully!</p>
        <Link to={"/MyOrders"} className='p-2 bg-green-700 hover:bg-white border-2 border-green-700 hover:text-green-700 rounded text-sm font-bold text-white'>Check Order</Link>
    </div>
  )
}

export default Paymentsuccessing