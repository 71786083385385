import React, { useContext, useState } from 'react'
import '../App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import icons8user from '../assets/icons/icons8-user.gif';
import SummaryApi from '../common';
import { toast } from "react-toastify";
import Context from '../context';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: ""
  });
  const navigate = useNavigate()
  const  {fetchUserDetails, fetchUserAddToCart} = useContext(Context)
  // console.log("generalContext", generalContext.fetchUserDetails())

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataResponse = await fetch(SummaryApi.signIn.url, {
        method: SummaryApi.signIn.method,
        credentials : 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      const dataApi = await dataResponse.json();
     
     
      if (dataApi.success) {
        toast.success(dataApi.message);
        navigate('/')
        fetchUserDetails()
        fetchUserAddToCart()

      } else if (dataApi.error) {
        toast.error(dataApi.message); // Ensure you're showing the error message properly
      }
   
   
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  console.log("data login", data);
  return (
    <div>
      {/* <Routes>
        <Route path="/" element={<Header />} />
      </Routes> */}
      <section id='Login'>
        <div className='mx-auto w-full max-w-[2560px] container p-4'>
          <div className='bg-white p-5 w-full max-w-sm mx-auto rounded'>
            <div className='w-20 h-20 mx-auto'>
              <img src={icons8user} alt='login icon' className="w-35 h-24 object-contain" />
            </div>
            <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
              <div className='grid'>
                <label>Email:</label>
                <div className='bg-slate-100 p-2'>
                  <input
                    type='email'
                    value={data.email}
                    onChange={handleOnChange}
                    name='email'
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='email address'
                  />
                </div>
              </div>
              <div className='grid'>
                <label>Password:</label>
                <div className='bg-slate-100 p-2 flex'>
                  <input
                    type={showPassword ? "text" : "password"}
                    name='password'
                    onChange={handleOnChange}
                    value={data.password}
                    className='w-full h-full outline-none bg-transparent'
                    placeholder='Password'
                  />
                  <div className='cursor-pointer text-xl' onClick={() => setShowPassword((prev) => !prev)}>
                    <span>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <Link to={'../forgot-password'} className='block text-blue-600 w-fit ml-auto hover:underline hover:text-red-600'>
                  Forgot Password?
                </Link>
              </div>
              <button className='hover:bg-blue-600 bg-orange-600 text-white px-5 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                Login
              </button>
            </form>
            <p className='my-5'>
              Don't have an account? <Link to={"../sign-up"} className='hover:text-red-700 text-blue-700 hover:underline'>Sign Up</Link>
            </p>
          </div>
        </div>
      </section>
      {/* <Routes>
        <Route path="/" element={<Footer />} />
      </Routes> */}
    </div>
  )
};

export default Login;
