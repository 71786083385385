import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CiEdit } from 'react-icons/ci';
import moment from 'moment';
import UpdateUser from '../components/UpdateUser';
import SummaryApi from '../common';
import '../App.css';

const MyProfile = () => {
  const [allUser, setAllUsers] = useState([]);
  const [openUpdateUser, setOpenUpdateUser] = useState(false);
  const [updateUserDetails, setUpdateUserDetails] = useState({
    email: '',
    name: '',
    location: '',
    role: '',
    _id: ''
  });

  const fetchAllUsers = async () => {
    const fetchData = await fetch(SummaryApi.MyProfile.url, {
      method: SummaryApi.MyProfile.method,
      credentials: 'include'
    });
    const dataResponse = await fetchData.json();

    if (dataResponse.success) {
      setAllUsers(dataResponse.data);
    } else {
      toast.error(dataResponse.message);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className='bg-white pb-4'>
      <table className='w-full userTable'>
        <thead>
          <tr className='bg-orange-400 text-white'>
            <th>Sr. </th>
            <th>Name</th>
            <th>Email</th>
            <th>Location</th>
            <th>Mobile Number</th>
            <th>Role</th>
            <th>Created Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allUser.map((el, index) => (
            <tr key={el._id}>
              <td>{index + 1}</td>
              <td>{el?.name}</td>
              <td>{el?.email}</td>
              <td>{el?.location}</td>
              <td>{el?.phone}</td>
              <td>{el?.role}</td>
              <td>{moment(el?.createdAt).format('LL')}</td>
              <td>
                <button
                  className='bg-green-100 p-2 rounded-full cursor-pointer hover:bg-green-500'
                  onClick={() => {
                    setUpdateUserDetails(el);
                    setOpenUpdateUser(true);
                  }}
                >
                  <CiEdit />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {openUpdateUser && (
        <UpdateUser
          onClose={() => setOpenUpdateUser(false)}
          profilePic = {updateUserDetails.profilePic}
          name={updateUserDetails.name}
          email={updateUserDetails.email}
          location={updateUserDetails.location}
          role={updateUserDetails.role}
          userId={updateUserDetails._id}
          callFunc={fetchAllUsers}
        />
      )}
    </div>
  );
};

export default MyProfile;
