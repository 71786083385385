import React, { useEffect, useState } from 'react';
import SummaryApi from '../common';
import moment from "moment";
import displayTZSCurrency from '../helpers/DisplayCurrency';

const SellerOrder = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchOrderDetails = async () => {
        try {
            const response = await fetch(SummaryApi.SellerOrder.url, {
                method: SummaryApi.SellerOrder.method,
                credentials: 'include',
            });
            const responseData = await response.json();
            if (responseData.success) {
                setData(responseData.data || []);
            } else {
                console.error('Failed to fetch orders:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='h-[calc(100vh-190px)] overflow-y-scroll'>
            <h2 className='bg-orange-400 text-white font-medium text-lg text-center justify-center'>All Orders </h2>
            {
                data.length === 0 ? (
                    <p>No Order Available right now</p>
                ) : (
                    <div className='p-4 w-full'>
                        {
                            data.map((item, index) => (
                                <div key={item._id || index}>
                                    <p className='font-bold text-sm'>{moment(item.createdAt).format('LLL')}</p>
                                    <div className='border rounded'>
                                        <div className='flex justify-between flex-col lg:flex-row'>
                                            <div className='grid gap-1'>
                                                {
                                                    item.productDetails && item.productDetails.length > 0 ? (
                                                        item.productDetails.map((product, idx) => (
                                                            <div key={product.productId || idx} className='flex gap-3 bg-slate-100'>
                                                                <div className='w-28 h-28 bg-slate-200'>
                                                                    <img 
                                                                        src={product.image[0]} 
                                                                        className='w-28 h-28 object-scale-down p-2 mix-blend-multiply' 
                                                                        alt={product.name} 
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className='font-bold text-lg ellipsis line-clamp-1'>
                                                                        {product.name}
                                                                    </div>
                                                                    <div className='flex items-center gap-5 mt-1'>
                                                                        <p>Quantity: {product.quantity}</p>
                                                                        <div className='text-orange-500'>
                                                                            {displayTZSCurrency(product.price)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No product details available.</p>
                                                    )
                                                }
                                            </div>
                                            <div className='flex flex-col gap-4 p-2 min-w-[300px]'>
                                                <div>
                                                    <div className='text-lg font-medium'>Payment Details</div>
                                                    <p className='ml-1 capitalize'>Payment Method: {item.paymentDetails?.payment_method_type[0]}</p>
                                                    <p className='ml-1 capitalize'>Payment Status: <b className='text-green-500'> {item.paymentDetails?.payment_status}</b> </p>
                                                    <p className='ml-1'>Transaction ID: <c className='text-green-500'> {item.paymentDetails?.paymentId}</c></p>
                                                    <p className='ml-1'>
                                                        Email ID: <a href={`mailto:${item.email}`} className='text-green-500'>{item.email}</a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <div className='ml-1 font-medium capitalize'>Shipping Details</div>
                                                    {
                                                        item.shipping_options && item.shipping_options.length > 0 ? (
                                                            item.shipping_options.map((shipping, idx) => (
                                                                <div key={shipping.shipping_rate || idx} className='ml-1 capitalize'>
                                                                    Shipping Amount: {displayTZSCurrency(shipping.shipping_amount)}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>No shipping details available.</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='font-bold ml-auto w-fit lg:text-lg mr-1 text-orange-600'>
                                            Total Amount: {displayTZSCurrency(item.totalAmount)}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default SellerOrder;
