import React, { useState } from 'react'
import { CgClose } from "react-icons/cg";
import productCategory from "../helpers/productCategory";
import { FaCloudUploadAlt } from "react-icons/fa";
import uploadImage from '../helpers/uploadImage';
import DisplayImage from './DisplayImage';
import { RiDeleteBin6Line } from "react-icons/ri";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import productCondition from '../helpers/condition';

const AdminEditProduct = ({
onClose,
productData,
fetchdata
}) => {

    const [data, setData] = useState({
        ...productData,
        productName: productData?.productName,
        brandName: productData?.brandName,
        category: productData?.category,
        productImage: productData?.productImage || [],
        description: productData?.description,
        price: productData?.price,
        sellingPrice: productData?.sellingPrice,
        condition: productData?.condition,
        stock: productData?.stock
    })
    const [openFullScreenImage, setOpenFullScreenImage] = useState(false)
    const [fullScreenImage, setFullScreenImage] = useState("")
    // const [uploadProductImageInput, setUploadProductImageInput] = useState("")
    const handleOnChange = (e) => {
        const { name, value } = e.target
        setData((preve) => {
            return {
                ...preve,
                [name]: value


            }
        })
    }
    const handleUploadProduct = async (e) => {

        const file = e.target.files[0]
        const uploadImageCloudinary = await uploadImage(file)

        setData((preve) => {
            return {
                ...preve,
                productImage: [...preve.productImage, uploadImageCloudinary.url]
            }
        })

    }
    const handleDeleteProductImage = async (index) => {
        // console.log("image index", index)

        const newProductImage = [...data.productImage]
        newProductImage.splice(index, 1)
        setData((preve) => {
            return {
                ...preve,
                productImage: [...newProductImage]
            }
        })
    }

    // upload product
    const handleSubmit = async(e) => {
        e.preventDefault()

        const response = await fetch (SummaryApi.updateProduct.url,{

            method : SummaryApi.updateProduct.method,
            credentials : 'include',
            headers : {
                "content-type" : "application/json"
            },
            body : JSON.stringify(data)
        })

        const responseData = await response.json()
        if (responseData.success) {
            toast.success(responseData?.message)
            onClose()
            fetchdata()
        }


        if (responseData.error) {
            toast.error(responseData?.message)
        }
    }

  return (
    <div className='w-full bg-black bg-opacity-50 h-full absolute left-0 bottom-0 top-0 right-0 flex justify-center items-center'>
    <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[88%] overflow-hidden'>

        <div className='justify-between items-center flex pb-3'>
            <h2 className='font-bold text-lg'>Edit Product</h2>
            <div onClick={onClose} className='w-fit ml-auto text-2xl hover:text-red-500 cursor-pointer'>
                <CgClose />
            </div>
        </div>



        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
            <label htmlFor='productName'>Product Name</label>
            <input
                type='text'
                id='productName'
                name='productName'
                placeholder='enter product name'
                value={data.productName}
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded'
            />

            <label htmlFor='brandName' className='mt-3'>Brand Name</label>
            <input
                type='text'
                id='brandName'
                name='brandName'
                placeholder='enter product name'
                value={data.brandName}
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded'
            />

            <label htmlFor='category' className='mt-3'>Product Category</label>
            <select value={data.category} name='category' onChange={handleOnChange} className='p-2 bg-slate-100 border rounded'>
            <option value="">--Select Category--</option>

                
                {

                    productCategory.map((el, index) => {
                        return (
                            <option value={el.value} key={el.value + index}>{el.value}</option>
                        )
                    })
                }

            </select>

            <label htmlFor='productImage' className='mt-3'>Product Image</label>
            <label htmlFor='uploadImageInput'>
                <div className='p-2 cursor-pointer flex justify-center items-center bg-slate-100 border rounded h-32 w-full'>
                    <div className='text-blue-500 flex justify-center items-center flex-col gap-2 '>
                        <span className='text-3xl'><FaCloudUploadAlt /></span>
                        <p className='text-sm'> Upload product Image</p>
                        <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadProduct} />
                    </div>
                </div>
            </label>
            <div>
                {
                    data?.productImage[0] ? (
                        <div className='flex items-center gap-2'>
                            {
                                data.productImage.map((el, index) => {
                                    return (
                                        <div className=' relative group'>
                                            <img
                                                src={el} alt={el}
                                                width={80} height={80}
                                                className='bg-slate-100 border cursor-pointer'
                                                onClick={() => {
                                                    setOpenFullScreenImage(true)
                                                    setFullScreenImage(el)

                                                }
                                                } />
                                            <div className='absolute bottom-0 cursor-pointer right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block' onClick={() => handleDeleteProductImage(index)}>
                                                <RiDeleteBin6Line />
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    ) : (
                        <p className='text-red-600 text-xs'>*Please upload product Image</p>
                    )
                }
            </div>

            <label htmlFor='condition' className='mt-3'>Product condition</label>
            <select value={data.condition} name='condition' onChange={handleOnChange} className='p-2 bg-slate-100 border rounded'>
            <option value="">--Select Condition--</option>

                
                {

                    productCondition.map((condition, index) => {
                        return (
                            <option value={condition.value} key={condition.value + index}>{condition.value}</option>
                        )
                    })
                }

            </select>
            <label htmlFor='stock' className='mt-3'>Stock</label>
            <input
                type='number'
                id='stock'
                name='stock'
                placeholder='enter product stock'
                value={data.stock}
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded'
            />
            <label htmlFor='price' className='mt-3'>Price</label>
            <input
                type='number'
                id='price'
                name='price'
                placeholder='enter product Price'
                value={data.price}
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded'
            />


            <label htmlFor='sellingPrice' className='mt-3'>selling Price</label>
            <input
                type='number'
                id='sellingPrice'
                name='sellingPrice'
                placeholder='enter product selling Price'
                value={data.sellingPrice}
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded'
            />


<label htmlFor='description' className='mt-3'>Product Description</label>
            <textarea 
            className='h-28 bg-slate-100 border resize-none p-1' 
            rows={3} 
            onChange={handleOnChange}
            name='description'
            value={data.description}
            >
                
                </textarea>


            <button className='px-3 py-1 bg-orange-600 text-white mb-10 hover:bg-orange-700'>Update Product</button>
        </form>



    </div>


    {/* display full image screen */}
    {
        openFullScreenImage && (
            <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />

        )
    }
</div>
  )
}

export default AdminEditProduct