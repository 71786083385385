import React, { useState } from 'react';
import ROLE from '../common/role';
import { IoMdClose } from "react-icons/io";
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import cities from '../common/cities';
import icons8user from '../assets/icons/icons8-user.gif';
import imageTobase64 from '../helpers/imageTobase64';

const ChangeUserRole = ({
  profilePic,
  name,
  email,
  location,
  phone,
  role,
  userId,
  onClose,
  callFunc,
}) => {
  const [userProfilePic, setUserProfilePic] = useState(profilePic);
  const [userRole, setUserRole] = useState(role);
  const [newEmail, setNewEmail] = useState(email);
  const [newLocation, setNewLocation] = useState(location);
  const [newPhone, setNewPhone] = useState(phone);

  const handleUploadPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePic = await imageTobase64(file);
      setUserProfilePic(imagePic);
    }
  };

  const handleRoleChange = (e) => {
    setUserRole(e.target.value);
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleLocationChange = (e) => {
    setNewLocation(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setNewPhone(e.target.value);
  };

  const updateUserDetails = async () => {
    const fetchResponse = await fetch(SummaryApi.updateUserDetails.url, {
      method: SummaryApi.updateUser.method,
      credentials: 'include',
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        profilePic: userProfilePic,
        role: userRole,
        email: newEmail,
        location: newLocation,
        phone: newPhone,
      }),
    });

    const responseData = await fetchResponse.json();
    if (responseData.success) {
      toast.success(responseData.message);
      onClose();
      callFunc();
    }
    console.log("User details updated", responseData);
  };

  return (
    <div className='top-0 bottom-0 left-0 right-0 fixed absolute w-full h-full z-10 flex justify-between items-center bg-black bg-opacity-50'>
      <div className='mx-auto bg-white shadow-md p-4 w-full max-w-sm'>
        <button className='block ml-auto' onClick={onClose}>
          <IoMdClose />
        </button>
        <h1 className='pb-4 text-lg font-medium'>Update User Details</h1>
        <div className='w-20 h-20 mx-auto relative overflow-hidden rounded-full'>
          <img
            src={userProfilePic || icons8user}
            alt="Profile Pic"
            className='w-full h-full object-cover'
          />
          <label className='absolute bottom-0 w-full text-xs cursor-pointer bg-opacity-80 bg-slate-200 pb-4 pt-2 text-center'>
            Upload Profile
            size less than 70kb
            <input
              type="file"
              className='hidden'
              accept="image/*"
              onChange={handleUploadPic}
            />
          </label>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Username</label>
          <input
            disabled
            type='text'
            value={name}
            className='border bg-slate-300 text-center px-4 py-1 w-full'
          />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Email</label>
          <input
            type='text'
            value={newEmail}
            onChange={handleEmailChange}
            className='border px-4 py-1 w-full'
          />
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Location</label>
          <select
            className='border px-4 py-1'
            value={newLocation}
            onChange={handleLocationChange}
          >
            <option value="">--Select Your Location--</option>
            {cities.map((el, index) => (
              <option value={el.value} key={el.value + index}>
                {el.value}
              </option>
            ))}
          </select>
        </div>
        <div className='mb-4'>
          <label className='block text-gray-700'>Phone Number</label>
          <input
            type='number'
            value={newPhone}
            onChange={handlePhoneChange}
            className='border px-4 py-1 w-full'
          />
        </div>
        <div className='flex items-center justify-between'>
          <p>Status</p>
          <select
            className='border px-4 py-1 bg-slate-300'
            disabled
            value={userRole}
            onChange={handleRoleChange}
          >
            {Object.values(ROLE).map((el) => (
              <option value={el} key={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={updateUserDetails}
          className='w-fit mx-auto block border py-1 px-3 bg-orange-600 hover:bg-orange-700 text-white rounded-full'
        >
          Change Role
        </button>
      </div>
    </div>
  );
};

export default ChangeUserRole;
