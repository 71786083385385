import React from 'react'

const productCategory = [
    {id : 1, label : "Computers", value : "Computers"},
    {id : 2, label : "Computer Accessories", value : "Computer Accessories"},
    {id : 3, label : "Printers", value : "Printers"},
    {id : 4, label : "Printer Appliances", value : "Printer Appliances"},
    {id : 5, label : "Smart & Home Appliances", value : "Appliances"},
    {id : 6, label : "Phones & Tablets", value : "Tablets.Phones"},
    {id : 7, label : "Cameras & CCTV", value : "Cameras & CCTV"},
    {id : 8, label : "Processor", value : "Processor"},
    {id : 9, label : "Refrigerator", value : "Refrigerator"},
    {id : 11, label : "Speakers", value : "Speakers"},
    {id : 12, label : "Trimmers", value : "Trimmers"},
    {id : 13, label : "Televisions", value : "Televisions"},
    {id : 14, label : "Bag & Luggages", value : "Bags"},
    {id : 15, label : "watches", value : "watches"},
    {id : 16, label : "Gadgets & Peripherals", value : "Gadgets"},
    {id : 17, label : "Security", value : "Security"},
    {id : 18, label : "Biometrics", value : "Biometrics"},
    {id : 19, label : "Servers", value : "Servers"},
    {id : 20, label : "EPABX", value : "EPABX"},
    {id : 21, label : "Hotel POS", value : "Hotel POS"},
    {id : 22, label : "Electronics Appliances", value : "Electronics"},
    {id : 23, label : "Access Point", value : "Access Point"},
    {id : 24, label : "Networking Devices & Accessories", value : "Networking Devices & Accessories"},
    {id : 25, label : "Telecommunication", value : "Telecommunication"},
    {id : 25, label : "Shoes", value : "Shoes"},
    {id : 25, label : "Airphones, AirPodes & HeadPhones", value : "Airphones"},

    
    


    
]
 


export default productCategory