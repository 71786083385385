import React, { useContext, useState } from 'react';
import Logo from './Logo';
import './style.css';
import { GrSearch } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaShoppingCart } from "react-icons/fa";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import { useDispatch, useSelector } from "react-redux";
import SummaryApi from '../common';
import { toast } from "react-toastify";
import { setUserDetails } from '../stores/userSlice';
import ROLE from '../common/role';
import Context from '../context';


const Header = () => {
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()
  const [menuDisplay, setMenuDisplay] = useState(false)
  const context = useContext(Context)
  const Navigate = useNavigate()
const searchInput = useLocation()
const URLsearch = new URLSearchParams(searchInput?.search)
const searchQuery = URLsearch.getAll("sr")

const [search,setSearch] = useState(searchQuery)


  const handleLogout = async () => {
    const fetchData = await fetch(SummaryApi.logout_user.url, {
      method: SummaryApi.logout_user.method,
      credentials: 'include'
    })
    const data = await fetchData.json()

    if (data.success) {
      toast.success(data.message)
      dispatch(setUserDetails(null))
      Navigate("/")
    }
    if (data.error) {
      toast.error(data.message)
    }
  }
  const handleSearch = (e) =>{
    const {value} = e.target
    setSearch(value)
    if (value) {
      Navigate(`/search?sr=${value}`) 
    }else{
      Navigate('search')
    }
  }
  return (
  
    <header className='container w-full max-w-[2560px] h-16 fixed z-50 md:mb-10 '>
      <div>
      <div className='h-full shadow-md w-full max-w-[2560px] flex items-center bg-white container mx-auto px-4 justify-between'>
        <div className='justify-center gap-0'>
          <Link to={"/"} className='flex gap-2'>
            <Logo w={80} h={80}/>
            <div className='hidden lg:grid'>
            <p className='text-2xl font-bold text-orange-600'>ARUSHAJIJI</p>
            <p className='text-sm text-orange-600'>Online Shopping Mall</p>
            </div>
          </Link>
        </div>
        <div className='border focus-within:shadow rounded-full pl-3 hidden lg:flex items-center w-full justify-between max-w-sm'>
          <input value={search} onChange={handleSearch} type='text' className='w-full outline-none' placeholder='What are you looking?...' />
          <div className='text-lg min-w-[50px] flex items-center justify-center text-white rounded-r-full h-8 bg-orange-600'>
            <GrSearch />
          </div>
        </div>
       
       
        <div className='flex items-center gap-7'>
          <div className='relative flex justify-center'>
            {
              user?._id && (
                <div className='text-2xl cursor-pointer relative flex justify-center' onClick={() => setMenuDisplay(preve => !preve)}>
                  {
                    user?.profilePic ? (
                      <img src={user?.profilePic} className='w-10 h-10 rounded-full' alt={user?.name} />
                    ) : (
                      <FaRegCircleUser />
                    )
                  }

                </div>
              )
            }


            {
              menuDisplay && (

                <div className='absolute bg-white bottom-0 top-11 h-fit p-2 shadow-lg rounded'>
                  <nav>
                    {
                      user?.role === ROLE.ADMIN ? (
                   <div>
                         <Link to={"/admin-panel/all-products"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>Admin Panel</Link>
                        <Link to={"/MyOrders"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Orders</Link>
                        <Link to={"/MyProfile"} className='whitespace-nowrap hidden md:block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Profile</Link>

                   </div>
                      )
                   :
                        user?.role === ROLE.SELLER ? (
                      <div className='md:grid'>
                          <Link to={"/seller-panel/my-products"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}>My Panel</Link>
                        <Link to={"/MyOrders"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Order</Link>
                        <Link to={"/MyProfile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Profile</Link>


                      </div>
                      ) : (
                        <div className='md:grid'>
                        <Link to={"/MyOrders"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Order</Link>
                        <Link to={"/MyProfile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> My Profile</Link>
                        {/* <Link to={"/profile"} className='whitespace-nowrap block hover:bg-slate-100 p-2' onClick={() => setMenuDisplay(preve => !preve)}> Profile</Link> */}

                      </div>
                      )
                    }
                  </nav>
                </div>
              )
            }
          </div>

          {
            user?._id && (
              <Link to={"/cart"} className='text-2xl relative'>
                <span><FaShoppingCart /></span>
                <div className='bg-orange-600 -top-2 -right-3 absolute rounded-full h-5 text-white w-5 p-2 flex items-center justify-center'>
                  <p className='text-sm -ml-0.5'>{context?.cartProductCount}</p>
                </div>
              </Link>
            )
          }


          <div className=''>
            {
              user?._id ? (
                <button onClick={handleLogout} className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-orange-600'>Logout</button>
              )
                : (
                  <div>
                    <Link to={"/Login"} className='px-3 py-1 rounded-full text-white bg-orange-600 hover:bg-red-700'>Login</Link>
                    <c className='text-2xl leading-none'>|</c>
                  <Link to={"/sign-up"} className='px-3 py-1 rounded-full text-white bg-orange-600 hover:bg-red-700'>Register</Link>

                  </div>

                )
            }
          </div>

        </div>
      </div>
    
      <div className='border focus-within:shadow rounded flex lg:hidden items-center w-full min-w-[260px]  mx-auto justify-between'>
          <input value={search} onChange={handleSearch} type='text' className='w-full pl-3 outline-none h-8 w-full  min-w-[260px]' placeholder='What are you looking?...' />
          <div className='text-lg min-w-[50px] flex items-center justify-center text-white rounded-r h-8 bg-orange-600'>
            <GrSearch />
          </div>
        </div>

      </div>
    </header>
    

  );
}

export default Header;
// import React from 'react'

// const Header = () => {
//   return (
//     <div>Header</div>
//   )
// }

// export default Header
