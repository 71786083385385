
const backendDomain = process.env.REACT_APP_BACKEND_URL 
const SummaryApi = {
  signUp: {
    url: `${backendDomain}/api/signup`,
    method: "post"
  },
  signIn: {
    url: `${backendDomain}/api/signin`,
    method: "post"
  },
  current_user: {
    url: `${backendDomain}/api/user-details`,
    method: "get"
  },
  logout_user: {
    url: `${backendDomain}/api/userLogout`,
    method: 'get'
  },
  allUser: {

    url: `${backendDomain}/api/all-user`,
    method: 'get'
  },
  MyProfile: {
    url: `${backendDomain}/api/MyProfile`,
    method: 'get'
  },
  updateUser: {
    url: `${backendDomain}/api/update-user`,
    method: 'post'
  },

  updateuserpasswordReset :{
     url: `${backendDomain}/api/forgot-password`,
    method: 'post'
    
  },
  updateUserDetails :{
 url: `${backendDomain}/api/update-user-details`,
    method: 'post'
  },
  uploadProduct: {
    url: `${backendDomain}/api/upload-product`,
    method: 'post'
  },
  allProduct: {
    url: `${backendDomain}/api/get-product`,
    method: 'get'
  },

  MyProducts: {
    url: `${backendDomain}/api/my-products`,
    method: 'get'
  },
  updateProduct: {
    url: `${backendDomain}/api/update-product`,
    method: 'post'
  },
  categoryProduct: {
    url: `${backendDomain}/api/get-categoryproduct`,
    method: 'get'
  },
  categoryWiseProduct: {
    url: `${backendDomain}/api/category-product`,
    method: 'post'
  },

  productDetails: {
    url: `${backendDomain}/api/product-details`,
    method: 'post'
  },
  addToCartProduct: {
    url: `${backendDomain}/api/addToCart`,
    method: 'post'
  },
  addToCartProductCount: {
    url: `${backendDomain}/api/countAddTocartProduct`,
    method: 'get'
  },
  addToCartProductView: {
    url: `${backendDomain}/api/view-card-product`,
    method: 'get'
  },
  updateCartProduct: {
    url: `${backendDomain}/api/update-cart-product`,
    method: 'post'
  },
  deleteCartProduct: {
    url: `${backendDomain}/api/delete-cart-product`,
    method: 'post'
  },
  deleteProduct: {
    url: `${backendDomain}/api/delete-product`,
    method: 'post'
  },
  searchProduct: {
    url: `${backendDomain}/api/search`,
    method: 'get'
  },
  filterProduct: {
    url: `${backendDomain}/api/filter-product`,
    method: 'post'
  },
  payment: {
    url: `${backendDomain}/api/checkout`,
    method: 'post'
  },
  getOrder: {
    url: `${backendDomain}/api/order-list`,
    method: 'get',
  },
  allOrder: {
    url: `${backendDomain}/api/all-order`,
    method: 'get',
  },
  SellerOrder: {
    url: `${backendDomain}/api/all-orders`,
    method: 'get',
  }
  
}
export default SummaryApi