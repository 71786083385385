import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Link, Route, Routes, Outlet, useNavigate } from 'react-router-dom'
import { FaRegCircleUser } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import AllProducts from './AllProducts'
import AllUsers from './AllUsers'
import ROLE from '../common/role'

const AdminPanel = () => {
    const user = useSelector(state => state?.user?.user)

    const navigate =useNavigate()
useEffect(()=>{
if (user?.role !== ROLE.ADMIN) {
  navigate("/")
}
},[user])
  return (
    <div>
     {/* <Routes>
        <Route path="/" element={<Header />} />
      </Routes>     */}
        <div className='min-h-[calc(100vh-120px)] mt-1 md:flex hidden'>

        
      <aside className='bg-white min-h-full w-full max-w-60 customShadow'>
     <div className='h-32 flex justify-center items-center flex-col '>
      <div className='text-5xl cursor-pointer relative flex justify-center' >
        {
          user?.profilePic ? (
            <img src={user?.profilePic} className='w-20 h-20 rounded-full'alt={user?.name} />
          ):(
            <FaRegCircleUser />
          )
        }
      
      </div> 
      <p className='capitalize text-lg font-semibold'>{ user?.name }</p>
      <p className='text-sm'>My Account</p>
      </div>
      <div>

{/* navigation */}

<nav className='grid p-4'>
<Link to={"/"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>Home</Link>
  
    <Link to={"all-users"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>All Users</Link>
    <Link to={"all-products"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>All Products</Link>
    <Link to={"my-products"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>My Products</Link>

    <Link to={"all-orders"} className='px-2 py-1 hover:bg-orange-500 hover:text-white'>All Orders</Link>

</nav>
      </div>

      </aside>
       
      <main className='w-full h-full p-2'>
     <Outlet/>
      </main>

      </div>
        {/* <Routes>
        <Route path="/" element={<Footer />} />
      </Routes> */}
    </div>
  )
}

export default AdminPanel